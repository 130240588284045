"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._Months = exports._GetAgeFromBirthday = exports._GetUserLocalTime = exports._GetTodaysDateAsISOString = exports._ProperISODate = void 0;
var _ProperISODate = function (year, month, day) {
    var paddedMonth = String(month).padStart(2, '0');
    var paddedDay = String(day).padStart(2, '0');
    return "".concat(year, "-").concat(paddedMonth, "-").concat(paddedDay);
};
exports._ProperISODate = _ProperISODate;
var _GetTodaysDateAsISOString = function () {
    return (0, exports._GetUserLocalTime)().toISOString().replace(/T.*/gi, '');
};
exports._GetTodaysDateAsISOString = _GetTodaysDateAsISOString;
var _GetUserLocalTime = function () {
    var today = new Date();
    var timezoneOffset = today.getTimezoneOffset() * 60000; // offset in milliseconds
    return new Date(today.getTime() - timezoneOffset);
};
exports._GetUserLocalTime = _GetUserLocalTime;
var _GetAgeFromBirthday = function (selectedBirthDate) {
    return Math.floor((new Date().getTime() - new Date(selectedBirthDate).getTime()) / 3.15576e10);
};
exports._GetAgeFromBirthday = _GetAgeFromBirthday;
exports._Months = [
    { month: 1, name: 'January', short: 'Jan' },
    { month: 2, name: 'February', short: 'Feb' },
    { month: 3, name: 'March', short: 'Mar' },
    { month: 4, name: 'April', short: 'Apr' },
    { month: 5, name: 'May', short: 'May' },
    { month: 6, name: 'June', short: 'Jun' },
    { month: 7, name: 'July', short: 'Jul' },
    { month: 8, name: 'August', short: 'Aug' },
    { month: 9, name: 'September', short: 'Sep' },
    { month: 10, name: 'October', short: 'Oct' },
    { month: 11, name: 'November', short: 'Nov' },
    { month: 12, name: 'December', short: 'Dec' },
];
