"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagiclineApi = void 0;
var generator_const_1 = require("@src/const/dom-crud/generator.const");
var backend_services_1 = require("@src/services/backend-services");
var object_service_1 = require("../services/object-service");
var settings_service_1 = require("../services/settings.service");
var MagiclineApi = /** @class */ (function () {
    function MagiclineApi() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.cacheOfferings = {};
    }
    MagiclineApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, backend_services_1.betterFetch)(this.config.get.magicLineUrl + '/connect/v2/studio', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    MagiclineApi.prototype.createNewCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // Create a new customer and a new contract
                return [2 /*return*/, fetch(this.config.get.magicLineUrl + '/connect/v1/rate-bundle', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        var _a, _b;
                        if (((_b = (_a = _this === null || _this === void 0 ? void 0 : _this.config) === null || _a === void 0 ? void 0 : _a.get) === null || _b === void 0 ? void 0 : _b.environment) === 'dev') {
                            console.log('created', created);
                        }
                        // Write response in localstorage for later usage
                        var dataArray = created;
                        for (var key in dataArray) {
                            if (dataArray[key]) {
                                localStorage.setItem(key, dataArray[key]);
                            }
                        }
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    MagiclineApi.prototype.createNewUnderageCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // Create a new customer and a new contract
                return [2 /*return*/, (0, backend_services_1.betterFetch)(this.config.get.publicCloudFunction + '/member/underage/create', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(__assign(__assign({}, body), { tenant: this.config.get.tenantKey })),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        var _a, _b;
                        if (((_b = (_a = _this === null || _this === void 0 ? void 0 : _this.config) === null || _a === void 0 ? void 0 : _a.get) === null || _b === void 0 ? void 0 : _b.environment) === 'dev') {
                            console.log('created', created);
                        }
                        // Write response in localstorage for later usage
                        var dataArray = created;
                        for (var key in dataArray) {
                            if (dataArray[key]) {
                                localStorage.setItem(key, dataArray[key]);
                            }
                        }
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    MagiclineApi.prototype.createLead = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch(this.config.get.magicLineUrl + '/connect/v1/lead', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    // This can be used e.g. to validate a voucher
    MagiclineApi.prototype.previewNewCustomer = function (body) {
        return (0, backend_services_1.betterFetch)(this.config.get.magicLineUrl + '/connect/v1/preview', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    };
    MagiclineApi.prototype.getOfferings = function (studioId_1) {
        return __awaiter(this, arguments, void 0, function (studioId, disableCache) {
            var res, body, _i, body_1, offer, _a, _b, term, error, error_1;
            var _c, _d, _e, _f;
            if (disableCache === void 0) { disableCache = false; }
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (((_d = (_c = this.cacheOfferings) === null || _c === void 0 ? void 0 : _c[studioId]) === null || _d === void 0 ? void 0 : _d.length) && !disableCache) {
                            console.info(studioId + ' offerings loaded from cache!');
                            return [2 /*return*/, this.cacheOfferings[studioId]];
                        }
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, (0, backend_services_1.betterFetch)(this.config.get.magicLineUrl + '/connect/v1/rate-bundle?studioId=' + studioId, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })];
                    case 2:
                        res = _g.sent();
                        if (!res.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, res.json()];
                    case 3:
                        body = (_g.sent());
                        if (body) {
                            for (_i = 0, body_1 = body; _i < body_1.length; _i++) {
                                offer = body_1[_i];
                                for (_a = 0, _b = offer.terms; _a < _b.length; _a++) {
                                    term = _b[_a];
                                    //Magicline doesnt support currency codes on term level so we set by default EUR
                                    term.currencyCode = 'EUR';
                                }
                            }
                            this.cacheOfferings[studioId] = body;
                            // if (this.config.get?.stripe?.stripeKey) {
                            //   const url = `https://europe-west3-named-catcher-373207.cloudfunctions.net/stripe-public-endpoint/products?accountId=${this.config.get.stripe.accountId}&studioId=${studioId}`;
                            //   const res: { termId: string; priceIds: string[] }[] = await (
                            //     await betterFetch(url, {
                            //       method: 'GET',
                            //       headers: {
                            //         'Content-Type': 'application/json',
                            //       },
                            //     })
                            //   ).json();
                            //   if (res.length === 0) {
                            //     console.error(
                            //       'No Stripe products found please check that the given contract names ',
                            //       ...body.map((a) => a.name),
                            //       ' are set in stripe!'
                            //     );
                            //   } else {
                            //     for (const contract of body) {
                            //       for (const term of contract.terms) {
                            //         for (const stripeProduct of res) {
                            //           if (String(term.id) === stripeProduct.termId) {
                            //             term.stripePriceIds = stripeProduct.priceIds;
                            //           }
                            //           // const product = body.find(
                            //           //   (a) => a.name === stripeProduct.name
                            //           // );
                            //           // if (product) {
                            //           //   product.stripePriceId = stripeProduct.default_price;
                            //           // } else {
                            //           //   if (environment === 'dev') {
                            //           //     console.error(
                            //           //       'Couldnt map stripe product',
                            //           //       res.map((a) => a.name),
                            //           //       stripeProduct,
                            //           //       ' with magicline products ',
                            //           //       body.map((a) => a.name),
                            //           //       body
                            //           //     );
                            //           //   }
                            //           // }
                            //         }
                            //       }
                            //     }
                            //     console.log('new mapping', body);
                            //   }
                            // }
                            return [2 /*return*/, body];
                        }
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, res.json()];
                    case 5:
                        error = _g.sent();
                        throw error;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _g.sent();
                        if (((_e = error_1 === null || error_1 === void 0 ? void 0 : error_1.message) === null || _e === void 0 ? void 0 : _e.length) || ((_f = error_1 === null || error_1 === void 0 ? void 0 : error_1.errorMessage) === null || _f === void 0 ? void 0 : _f.length)) {
                            return [2 /*return*/, undefined];
                        }
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    MagiclineApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var res, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.previewNewCustomer(body)];
                    case 1:
                        res = _a.sent();
                        if (!!res.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        error = _a.sent();
                        throw error;
                    case 3: return [4 /*yield*/, res.json()];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MagiclineApi.prototype.getTrialBookings = function (studioId, from, to) {
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, url;
            var _this = this;
            return __generator(this, function (_a) {
                startDate = from.toISOString().split('T')[0];
                endDate = to.toISOString().split('T')[0];
                console.log('from', from, 'to iso', startDate, ', end', to, 'to iso', endDate);
                url = "".concat(this.config.get.magicLineUrl, "/connect/v1/trialsession?studioId=").concat(studioId, "&startDate=").concat(startDate, "&endDate=").concat(endDate);
                return [2 /*return*/, (0, backend_services_1.betterFetch)(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    console.log('error getting trail booking', error);
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    MagiclineApi.prototype.createTrialBooking = function (bookingData) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // Create a new trail booking
                return [2 /*return*/, (0, backend_services_1.betterFetch)(this.config.get.magicLineUrl + '/connect/v1/trialsession/book', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(bookingData),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        var _a, _b;
                        if (((_b = (_a = _this === null || _this === void 0 ? void 0 : _this.config) === null || _a === void 0 ? void 0 : _a.get) === null || _b === void 0 ? void 0 : _b.environment) === 'dev') {
                            console.log('created', created);
                        }
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    return MagiclineApi;
}());
exports.MagiclineApi = MagiclineApi;
