"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemisoftApi = void 0;
var generator_const_1 = require("@src/const/dom-crud/generator.const");
var object_service_1 = require("../services/object-service");
var settings_service_1 = require("../services/settings.service");
var backend_services_1 = require("@src/services/backend-services");
var ThemisoftApi = /** @class */ (function () {
    function ThemisoftApi() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    ThemisoftApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var studioUrl;
            var _this = this;
            return __generator(this, function (_a) {
                studioUrl = "".concat(this.config.get.themisoftUrl, "/studios?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, (0, backend_services_1.betterFetch)(studioUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var themisoftStudios, convertedStudios;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    console.log('studio res', createResponse);
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    themisoftStudios = (_a.sent());
                                    console.log('got studios', themisoftStudios);
                                    convertedStudios = this.convertThemisoftStudios(themisoftStudios);
                                    console.log('converted studios', convertedStudios);
                                    return [2 /*return*/, convertedStudios];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    ThemisoftApi.prototype.createNewCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var themisoftCustomer, createCustomerUrl;
            var _this = this;
            return __generator(this, function (_a) {
                themisoftCustomer = this.convertToThemisoftCustomer(body);
                createCustomerUrl = "".concat(this.config.get.themisoftUrl, "/customer?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, (0, backend_services_1.betterFetch)(createCustomerUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(themisoftCustomer),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    ThemisoftApi.prototype.createLead = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, (0, backend_services_1.betterFetch)("".concat(this.config.get.themisoftUrl, "/lead"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body),
                        })];
                }
                catch (e) {
                    console.error('themisoft lead request error', e);
                }
                return [2 /*return*/];
            });
        });
    };
    ThemisoftApi.prototype.getOfferings = function (studioId) {
        return __awaiter(this, void 0, void 0, function () {
            var offeringUrl;
            var _this = this;
            return __generator(this, function (_a) {
                offeringUrl = "".concat(this.config.get.themisoftUrl, "/offerings?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey), "&studioId=").concat(studioId);
                return [2 /*return*/, (0, backend_services_1.betterFetch)(offeringUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var themisoftTariffs, convertedOffers;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    themisoftTariffs = (_a.sent());
                                    console.log('got offerings', themisoftTariffs);
                                    convertedOffers = this.convertThemisoftOffers(themisoftTariffs);
                                    console.log('converted offerings', convertedOffers);
                                    return [2 /*return*/, convertedOffers];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    ThemisoftApi.prototype.convertThemisoftOffers = function (offerings) {
        var magiclineOffers = [];
        for (var _i = 0, offerings_1 = offerings; _i < offerings_1.length; _i++) {
            var offer = offerings_1[_i];
            magiclineOffers.push({
                id: offer.vv_id,
                description: offer.contract.vv_unterueberschrift,
                name: offer.contract.vv_bezeichnung,
                subDescription: offer.contract.vv_beschreibung, //.replace(/<\/br}>/g, "\n"),
                preuseType: '',
                terms: [this.convertThemisoftMembershipTerm(offer)],
                modules: [],
                maximumNumberOfSelectableModules: 0,
                selectableModules: [],
                rateCodeDto: [],
                contractTextBlocks: [],
                imageUrl: '',
                footnote: '',
                initialPaymentRequired: false,
                contractSignaturesRequired: false,
                onlinePaymentType: '',
                allowedPaymentChoices: [],
                limitedOfferingPeriod: undefined
            });
        }
        return magiclineOffers;
    };
    ThemisoftApi.prototype.convertThemisoftMembershipTerm = function (offer) {
        // Once again, they dont really offer a good way to get the membership out of the contract
        var membershipDetails = offer.AusgewaehlteVertragsdetails[0];
        if (membershipDetails.vd_fremdbezeichnung !== "Hauptvertrag") {
            console.error("Might have not selected themisoft membership as main contract:", membershipDetails, "out of offers", offer);
        }
        var fees = [];
        for (var i = 1; i < offer.AusgewaehlteVertragsdetails.length; i++) {
            var themisoftFee = offer.AusgewaehlteVertragsdetails[i];
            fees.push({
                identifier: themisoftFee.vd_bezeichnung,
                isStarterPackage: false,
                name: themisoftFee.vd_fremdbezeichnung,
                paidTimePeriodCalculationType: null,
                paymentFrequency: themisoftFee.vd_zahlungsintervall + " " + this.convertThemisoftUnitToMagicline(themisoftFee.vd_zahlungseinheit),
                paymentFrequencyType: 'RECURRING',
                paymentFrequencyUnit: this.convertThemisoftUnitToMagicline(themisoftFee.vd_zahlungseinheit),
                paymentFrequencyValue: +themisoftFee.vd_zahlungsintervall,
                price: themisoftFee.vd_betrag
            });
        }
        return {
            id: offer.vv_id,
            defaultContractStartDate: offer.v_beginn,
            contractStartDateOfUse: offer.v_zutrittab,
            termValue: offer.contract.vd_vertragsdauer,
            termValueWithoutBonusPeriod: offer.contract.vd_vertragsdauer,
            termUnit: this.convertThemisoftUnitToMagicline(offer.contract.vv_vertragsdauereinheit),
            paymentFrequencyValue: +membershipDetails.vd_zahlungsintervall,
            paymentFrequencyUnit: this.convertThemisoftUnitToMagicline(membershipDetails.vd_zahlungseinheit),
            paymentFrequencyType: 'RECURRING',
            price: offer.contract.vv_betrag,
            currencyCode: 'EUR',
            priceAdjustmentRules: [],
            convertedPriceAdjustmentRules: [],
            ageBasedAdjustmentDtos: [],
            flatFees: fees,
            extensionFixedTerm: membershipDetails.vd_verlaengerung,
            extensionFixedTermUnit: this.convertThemisoftUnitToMagicline(membershipDetails.vd_verlaengerungseinheit),
            extensionType: 'TERM_EXTENSION',
            cancellationPeriod: membershipDetails.vd_kuendigungsfrist,
            cancellationPeriodUnit: this.convertThemisoftUnitToMagicline(membershipDetails.vd_kuendigungseinheit),
            extensionCancellationPeriod: membershipDetails.vd_kuendigungsfrist,
            extensionCancellationPeriodUnit: this.convertThemisoftUnitToMagicline(membershipDetails.vd_kuendigungseinheit),
            rateBonusPeriods: [],
            rateStartPrice: null,
            optionalModules: []
        };
    };
    ThemisoftApi.prototype.convertThemisoftStudios = function (themisoftStudios) {
        return themisoftStudios.map(function (themisoftStudio) {
            var street = (themisoftStudio === null || themisoftStudio === void 0 ? void 0 : themisoftStudio.s_strasse) || "";
            var houseNumber = "";
            var match = street.match(/^(.+?)\s+(\d{1,5}[a-zA-Z]?([-/]\d+[a-zA-Z]?)?)$/);
            if (match) {
                street = match[1];
                houseNumber = match[2];
            }
            return {
                id: themisoftStudio.s_id,
                studioName: themisoftStudio.s_name,
                studioPhone: themisoftStudio.s_telefon,
                studioEmail: themisoftStudio.s_email,
                trialSessionBookable: themisoftStudio.s_probetrainingerlaubt,
                address: {
                    street: street,
                    city: themisoftStudio.s_ort || '',
                    zipCode: themisoftStudio.s_plz || '',
                    countryCode: 'DE',
                    countryCodeAlpha2: 'DE',
                    longitude: themisoftStudio.longitude,
                    latitude: themisoftStudio.latitude,
                    houseNumber: houseNumber,
                },
                zoneId: 'Unknown Zone',
                masterStudioId: null,
                openingDate: null,
                closingDate: null,
                openingHours: [],
                currencyCode: 'EUR',
            };
        });
    };
    ThemisoftApi.prototype.convertToThemisoftCustomer = function (body) {
        return;
    };
    //   // This can be used e.g. to validate a voucher
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ThemisoftApi.prototype.previewNewCustomer = function (body) {
        console.error('PREVIEW CUSTOMER NOT DEFINED FOR THEMISOFT API');
        return undefined;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ThemisoftApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.error('VOUCHERS NOT DEFINED FOR THEMISOFT API');
                return [2 /*return*/, undefined];
            });
        });
    };
    /**
     * Themisoft uses german "Monat" etc
     * @param themisoftUnit
     */
    ThemisoftApi.prototype.convertThemisoftUnitToMagicline = function (themisoftUnit) {
        if (themisoftUnit === "Monat" || themisoftUnit === "Monate") {
            return "MONTH";
        }
        else if (themisoftUnit === "Woche" || themisoftUnit === "Wochen") {
            return "WEEK";
        }
        else if (themisoftUnit === "Jahr" || themisoftUnit === "Jahre") {
            return "YEAR";
        }
        else {
            console.error("Unknown themisoft unit", themisoftUnit);
        }
    };
    return ThemisoftApi;
}());
exports.ThemisoftApi = ThemisoftApi;
