"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._ErrorMessages = void 0;
exports._ErrorMessages = {
    noStudioFound: { de: '', en: 'No studios found' },
    fieldIsRequired: { de: '', en: 'This field is required' },
    studioHasNoTrialBookings: {
        de: 'Kein Probetraining in diesem Montag gefunden',
        en: 'No trailbookings found for this month',
    },
    failedGettingTrailBookings: {
        de: 'Fehler bei der Übertragung des Probetrainings',
        en: 'Error trying to get trail bookings',
    },
};
