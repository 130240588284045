"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YondApi = void 0;
var generator_const_1 = require("@src/const/dom-crud/generator.const");
var lodash_1 = require("lodash");
var object_service_1 = require("../services/object-service");
var settings_service_1 = require("../services/settings.service");
var backend_services_1 = require("@src/services/backend-services");
var YondApi = /** @class */ (function () {
    function YondApi() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    YondApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var studioUrl;
            var _this = this;
            return __generator(this, function (_a) {
                studioUrl = "".concat(this.config.get.yondUrl, "/studios?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, (0, backend_services_1.betterFetch)(studioUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var yondStudios, convertedStudios;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    console.log('studio res', createResponse);
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    yondStudios = (_a.sent());
                                    console.log('got studios', yondStudios);
                                    convertedStudios = this.convertYondStudios(yondStudios);
                                    console.log('converted studios', convertedStudios);
                                    return [2 /*return*/, convertedStudios];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    YondApi.prototype.createNewCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var yondCustomer, createCustomerUrl;
            var _this = this;
            return __generator(this, function (_a) {
                yondCustomer = this.convertToYondCustomer(body);
                createCustomerUrl = "".concat(this.config.get.yondUrl, "/customer?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, (0, backend_services_1.betterFetch)(createCustomerUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(yondCustomer),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    YondApi.prototype.createLead = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    body.studioId = +localStorage.getItem('studioOid');
                    body.notes =
                        body.notes +
                            'Studio name: ' +
                            localStorage.getItem('studioName') +
                            ', studioId: ' +
                            localStorage.getItem('studioId') +
                            ', studioOid: ' +
                            localStorage.getItem('studioOid');
                }
                catch (e) {
                    console.error('yond lead error', e);
                }
                try {
                    return [2 /*return*/, (0, backend_services_1.betterFetch)("".concat(this.config.get.yondUrl, "/lead"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body),
                        })];
                }
                catch (e) {
                    console.error('yond lead request error', e);
                }
                return [2 /*return*/];
            });
        });
    };
    // yond takes the studio id from localstorage
    // its not used to get the right studio, its used for the mapping
    // yond has no offering/location mapping, so it comes from the settings
    YondApi.prototype.getOfferings = function (studioId) {
        return __awaiter(this, void 0, void 0, function () {
            var offeringUrl, selectedStudioId, tarifsToShow;
            var _this = this;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                console.log('offering yond settings', this.config.get.yond);
                offeringUrl = "".concat(this.config.get.yondUrl, "/offerings?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                if (((_a = this.config.get.yond) === null || _a === void 0 ? void 0 : _a.excludeTarifsWithExternalCode) !== undefined) {
                    offeringUrl += "&filter=".concat(encodeURIComponent(JSON.stringify(this.config.get.yond.excludeTarifsWithExternalCode)));
                }
                if (((_b = this.config.get.yond) === null || _b === void 0 ? void 0 : _b.includeTarifsWithExternalCode) !== undefined) {
                    offeringUrl += "&include=".concat(encodeURIComponent(JSON.stringify(this.config.get.yond.includeTarifsWithExternalCode)));
                }
                selectedStudioId = localStorage.getItem('studioId');
                if (selectedStudioId && selectedStudioId != '') {
                    console.log('selected Studio: ', selectedStudioId);
                    if ((_c = this.config.get.yond) === null || _c === void 0 ? void 0 : _c.studioLocationMapping) {
                        console.log('using yond studio tarif mapping');
                        tarifsToShow = this.config.get.yond.studioLocationMapping[selectedStudioId];
                        if (!tarifsToShow) {
                            console.error('Selected studio', selectedStudioId, 'has NO set tariff ids in the webflow settings, showing ALL tarifs!');
                        }
                        else {
                            offeringUrl += "&tariffIds=".concat(encodeURIComponent(JSON.stringify(tarifsToShow)));
                        }
                    }
                    else {
                        console.log('Not using yond studio mapping, this can cause long loading times!');
                    }
                }
                return [2 /*return*/, (0, backend_services_1.betterFetch)(offeringUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var yondTariffs, convertedOffers;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    yondTariffs = (_a.sent());
                                    console.log('got offerings', yondTariffs);
                                    convertedOffers = this.convertYondOffers(yondTariffs);
                                    console.log('converted offerings', convertedOffers);
                                    if (convertedOffers.filter(function (s) { return s.id === undefined; }).length) {
                                        console.error('Found a yond studio without id, filtering it out!');
                                        convertedOffers = convertedOffers.filter(function (s) { return s.id !== undefined; });
                                    }
                                    return [2 /*return*/, convertedOffers];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    YondApi.prototype.convertYondOffers = function (offerings) {
        var _this = this;
        var offers = [];
        offerings.forEach(function (offering) {
            offering.variants.forEach(function (variant) {
                var hasMembership = _this.yondVariantContainsMembership(variant);
                if (!hasMembership) {
                    console.error("offering", offering.current_version.name, "has a variant", variant.current_version.name, "with no membership", variant.current_version.services);
                }
                else {
                    var details = variant.current_version;
                    var subDes = _this.createYondSubdescription(variant);
                    var allModules = variant.current_version.services.map(function (service) { return _this.convertYondServiceToModule(service); });
                    var selectableModules = variant.current_version.services
                        .filter(function (serv) { return serv.optional === true; })
                        .map(function (service) { return _this.convertYondServiceToModule(service); });
                    offers.push({
                        id: +variant.oid,
                        description: variant.current_version.description,
                        name: variant.current_version.name,
                        subDescription: subDes,
                        preuseType: null,
                        terms: _this.convertYondOfferTerm(details, variant.oid),
                        modules: allModules, // all addons
                        maximumNumberOfSelectableModules: selectableModules.length,
                        selectableModules: selectableModules, // not per default in package
                        imageUrl: null,
                        footnote: 'Footnote', // highlight contract details
                    });
                }
            });
        });
        return offers;
    };
    // TODO: technically we have to take in count the period type (month, year) for the termValueWithoutBonusPeriod
    YondApi.prototype.convertYondOfferTerm = function (offering, variantOid) {
        var _this = this;
        var possibleServices = offering.services.filter(function (serv) { var _a; return serv.service.add_on === false && serv.optional === false && _this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); });
        if (possibleServices.length !== 1) {
            console.error('Found', possibleServices.length, 'Yond membership services in this variant, that are not addon and not optional, this is NOT supported and will lead to problems!', offering.services);
        }
        var service = offering.services.find(function (serv) { var _a; return serv.service.add_on === false && serv.optional === false && _this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); });
        if (!service) {
            console.error('All services for offering', offering.name, 'are addons or optional');
            return [];
        }
        // cancelation
        var cancellationPeriods = offering.cancellation_periods;
        var cancellationPeriod = cancellationPeriods.length > 0 ? cancellationPeriods[0].amount : null;
        var cancellationPeriodUnit = cancellationPeriods.length > 0 ? cancellationPeriods[0].period : null;
        // extension
        var extensionPeriods = offering.extension_periods;
        var extensionPeriod = extensionPeriods.length > 0 ? extensionPeriods[0].amount : null;
        var extensionPeriodUnit = extensionPeriods.length > 0 ? extensionPeriods[0].period : null;
        // recurring fees currently moved to optional modules, since magicline doesnt support recurring fees ?
        // EVERYTHING EXCEPT MEMBERSHIP AND NOT OPTIONAL IS A FEE
        var reoccuringFees = offering.services.filter(function (serv) { var _a; return serv.price_frequency_unit !== 'ONCE' && serv.optional === false && !_this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); });
        var resReoccuringFees = reoccuringFees.map(function (offer) {
            return {
                identifier: offer.service.name,
                name: offer.service.name,
                price: offer.service.price,
                paymentFrequencyType: 'RECURRING',
                paymentFrequencyUnit: offer.price_frequency_unit,
                paymentFrequencyValue: offer.price_frequency,
                paymentFrequency: offer.price_frequency + ' ' + offer.price_frequency_unit,
            };
        });
        var flatFees = offering.services.filter(function (serv) { var _a; return serv.price_frequency_unit === 'ONCE' && !_this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); });
        var resFlatFess = __spreadArray(__spreadArray([], resReoccuringFees, true), flatFees.map(function (offer) {
            return {
                identifier: offer.service.name,
                name: offer.service.name,
                price: offer.service.price,
                paymentFrequency: 'einmalig',
                paymentFrequencyType: 'NON_RECURRING',
            };
        }), true);
        // addons
        var optionalServices = offering.services.filter(function (serv) { return serv.service.add_on === true && serv.optional === true; });
        var optionalModules = optionalServices.map(function (offer) {
            return _this.convertYondServiceToModule(offer);
        });
        if (optionalModules.length > 0) {
            console.log('Yond variant oid', variantOid, 'offering', offering, 'has optional modules:', optionalModules);
        }
        return offering.contract_terms.map(function (term) {
            var yondTerm = {
                id: +variantOid,
                defaultContractStartDate: null, // date when the contract starts
                contractStartDateOfUse: null,
                termValue: term.amount, // important number of months/days per term
                termValueWithoutBonusPeriod: term.amount, //term.amount - service.bonus_period,
                termUnit: term.period, // month, year, week etc
                paymentFrequencyValue: service.price_frequency,
                paymentFrequencyUnit: service.price_frequency_unit, // month, year, week etc
                paymentFrequencyType: 'RECURRING', // recurring, or once
                price: service.price, // think its per month/term not total
                currencyCode: service.currency,
                priceAdjustmentRules: [],
                flatFees: resFlatFess,
                extensionFixedTerm: extensionPeriod,
                extensionFixedTermUnit: extensionPeriodUnit,
                extensionType: 'TERM_EXTENSION',
                cancellationPeriod: cancellationPeriod,
                cancellationPeriodUnit: cancellationPeriodUnit,
                extensionCancellationPeriod: null,
                extensionCancellationPeriodUnit: null,
                rateBonusPeriods: [],
                rateStartPrice: null,
                optionalModules: optionalModules, // add ons optional in contract,
            };
            if (service.price_periods.length > 1) {
                yondTerm.subsequentRateDto = _this.mapYondSubsequentPrices(service, yondTerm);
            }
            return yondTerm;
        });
    };
    YondApi.prototype.mapYondSubsequentPrices = function (service, term) {
        if (service.price_periods.length > 1) {
            var adjustment = service.price_periods[1];
            var termCopy = (0, lodash_1.cloneDeep)(term);
            termCopy.price = adjustment.price;
            var res = {
                terms: [termCopy],
            };
            return res;
        }
        return undefined;
    };
    YondApi.prototype.convertYondServiceToModule = function (service) {
        return {
            id: +service.service.oid,
            name: service.service.name,
            description: service.service.description,
            paymentFrequencyValue: service.service.price_frequency,
            paymentFrequencyType: service.service.price_frequency_unit === 'ONCE' ? 'ONCE' : 'RECURRING',
            paymentFrequencyUnit: service.service.price_frequency_unit,
            price: service.service.price,
        };
    };
    YondApi.prototype.createYondSubdescription = function (variant) {
        var _this = this;
        var _a;
        var benefitInclude = (_a = this.config.get.yond) === null || _a === void 0 ? void 0 : _a.includeBenefitDescriptionWithExternalCode;
        var service = variant.current_version.services.find(function (serv) { var _a; return serv.service.add_on === false && serv.optional === false && _this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); });
        if (!service) {
            console.error('No membership found for tarif variant', variant.current_version.name, 'can not generate subdescription!');
            return '';
        }
        var filteredBenefits = service.service.benefits.filter(function (ben) { return !benefitInclude || benefitInclude.length === 0 || benefitInclude.includes(ben.benefit.external_code); });
        var allBenefits = filteredBenefits.map(function (ben) { return ben.benefit.name; });
        var subDes = (allBenefits === null || allBenefits === void 0 ? void 0 : allBenefits.reduce(function (sum, item) { return sum + '\n' + item; }, '')) || '';
        return subDes;
    };
    YondApi.prototype.convertYondStudios = function (yondStudios) {
        return yondStudios.map(function (yondStudio) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return {
                id: yondStudio.id,
                yondStudioOid: yondStudio.oid,
                yondLegalEntitiyOid: yondStudio.legal_entity_oid,
                studioName: yondStudio.name,
                studioPhone: (_a = yondStudio.support_phone) === null || _a === void 0 ? void 0 : _a.number,
                studioEmail: (_b = yondStudio.support_email) === null || _b === void 0 ? void 0 : _b.address,
                trialSessionBookable: false,
                address: {
                    street: ((_c = yondStudio.address) === null || _c === void 0 ? void 0 : _c.street) || '',
                    city: ((_d = yondStudio.address) === null || _d === void 0 ? void 0 : _d.city) || '',
                    zipCode: ((_e = yondStudio.address) === null || _e === void 0 ? void 0 : _e.postal) || '',
                    countryCode: ((_f = yondStudio.address) === null || _f === void 0 ? void 0 : _f.country.dial_code) || '',
                    countryCodeAlpha2: ((_g = yondStudio.address) === null || _g === void 0 ? void 0 : _g.country.dial_code) || '', // Assuming the same as `countryCode`
                    longitude: yondStudio.longitude,
                    latitude: yondStudio.latitude,
                    houseNumber: (_h = yondStudio.address) === null || _h === void 0 ? void 0 : _h.house_nr,
                },
                zoneId: 'Unknown Zone', // Placeholder if zone data is not available
                masterStudioId: null,
                openingDate: null,
                closingDate: null,
                openingHours: [],
                currencyCode: 'EUR',
            };
        });
    };
    YondApi.prototype.convertToYondCustomer = function (body) {
        var _a, _b, _c;
        var selectedContractId = localStorage.getItem('contract.rateBundleTermId');
        var selectedStudioId = localStorage.getItem('studioOid');
        var selectedTermNum = localStorage.getItem('contract.rateBundleTermDuration');
        var legalEntityOid = localStorage.getItem('legalEntityOid');
        var givenConsents = ((_a = this.config.get.yond) === null || _a === void 0 ? void 0 : _a.givenConsentNames) || [];
        var contractStartDate = (_b = this.config.get.yond) === null || _b === void 0 ? void 0 : _b.contractStartDate;
        var contractUsageStartDate = (_c = this.config.get.yond) === null || _c === void 0 ? void 0 : _c.contractUsageStartDate;
        var bankInfo = body.customer.bankAccount;
        var testCustomer = localStorage.getItem('testCustomer') === 'true';
        var optionalModules = JSON.parse(localStorage.getItem('optionalModules') || '[]');
        var phonePrefix = localStorage.getItem('customer.phonePrefix');
        if (!phonePrefix) {
            console.error('Missing phone prefix');
            phonePrefix = '+49';
        }
        //console.log("using localstorage data contract", selectedContractId, "and studio", selectedStudioId, "with term", selectedTermNum, "and legal entitity", legalEntityOid)
        var customer = {
            // all of these are custom values needed by yond
            home_facility_oid: selectedStudioId,
            tariff_variant_oid: selectedContractId,
            selectedTerm: selectedTermNum,
            legalEntityOid: legalEntityOid,
            bankInfo: bankInfo,
            givenConsents: givenConsents,
            contractStartDate: contractStartDate,
            contractUsageStartDate: contractUsageStartDate,
            phonePrefix: phonePrefix,
            optionalModules: optionalModules,
            // the customer itself
            person: {
                gender: body.customer.gender == 'UNISEX' ? 'NONBINARY' : body.customer.gender,
                birth_dt: body.customer.dateOfBirth,
                test: testCustomer,
                name: {
                    first_name: body.customer.firstname,
                    last_name: body.customer.lastname,
                },
                addresses: [
                    {
                        country_oid: '65', // mapped in backend
                        city: body.customer.city,
                        postal: body.customer.zipCode,
                        street: body.customer.street,
                        house_nr: body.customer.houseNumber,
                        country_code: body.customer.countryCode,
                    },
                ],
                phones: [
                    {
                        number: phonePrefix + body.customer.telephone_mobile, // TODO interface does not include selected phone prefix
                        country_oid: '65',
                    },
                ],
                emails: [
                    {
                        address: body.customer.email,
                    },
                ],
            },
        };
        return customer;
    };
    //   // This can be used e.g. to validate a voucher
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    YondApi.prototype.previewNewCustomer = function (body) {
        console.error('PREVIEW CUSTOMER NOT DEFINED FOR YOND API');
        return undefined;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    YondApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.error('VOUCHERS NOT DEFINED FOR YOND API');
                return [2 /*return*/, undefined];
            });
        });
    };
    YondApi.prototype.yondCategoryIsMembership = function (category) {
        return category === "Membership" || category === "Memberships" || category === "Mitgliedschaft" || category === "Mitgliedschaften";
    };
    YondApi.prototype.yondVariantContainsMembership = function (variant) {
        var _this = this;
        return variant.current_version.services.filter(function (serv) { var _a; return serv.service.add_on === false && serv.optional === false && _this.yondCategoryIsMembership((_a = serv.service.category) === null || _a === void 0 ? void 0 : _a.name); }).length > 0;
    };
    return YondApi;
}());
exports.YondApi = YondApi;
