"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._AssetFolder = exports._DEVCloudFunctions = exports._CloudFunctions = exports._CloudFunctionTypes = exports._CloudRunFunctionEndpoint = exports._CloudFunctionEndpoint = void 0;
exports._GetCloudFunctionUrl = _GetCloudFunctionUrl;
var object_service_1 = require("@src/services/object-service");
var settings_service_1 = require("@src/services/settings.service");
exports._CloudFunctionEndpoint = 'https://europe-west3-named-catcher-373207.cloudfunctions.net';
var _CloudRunFunctionEndpoint = function (endpoint) {
    return "https://".concat(endpoint, "-729907915433.europe-west3.run.app");
};
exports._CloudRunFunctionEndpoint = _CloudRunFunctionEndpoint;
exports._CloudFunctionTypes = {
    leads: 'leads',
    sendcloud: 'sendcloud',
    fitpay: 'fitpay',
    underage: 'underage',
};
exports._CloudFunctions = {
    leads: (0, exports._CloudRunFunctionEndpoint)("leads"),
    sendcloud: exports._CloudFunctionEndpoint + '/sendcloud',
    fitpay: (0, exports._CloudRunFunctionEndpoint)('stripe-public-endpoint'),
    underage: (0, exports._CloudRunFunctionEndpoint)('underage-contract-approval'),
};
exports._DEVCloudFunctions = {
    leads: (0, exports._CloudRunFunctionEndpoint)("dev-leads"),
    sendcloud: exports._CloudFunctionEndpoint + '/sendcloud',
    fitpay: (0, exports._CloudRunFunctionEndpoint)('dev-stripe-public-endpoint'),
    underage: (0, exports._CloudRunFunctionEndpoint)('dev-underage-contract-approval'),
};
// TODO: Rework once every function uses cloud-run
function _GetCloudFunctionUrl(cloudFunction, env) {
    var config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    if (!env) {
        env = config.get.environment;
    }
    return env === 'dev'
        ? exports._DEVCloudFunctions[cloudFunction]
        : exports._CloudFunctions[cloudFunction];
}
exports._AssetFolder = 'https://files.fittec.io/files/webflow/new-magicline/assets';
