"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._IsInput = exports._IsVisible = exports._FindElement = void 0;
/**
 * Searches a dom object in children if parent isnt the element and just inside a data-module
 * @param $element
 * @param targetTag
 * @returns Cash | undefined if multiple found
 */
var _FindElement = function ($element, targetTag) {
    if ($element.is(targetTag)) {
        return $element; // Return itself if it's already the target element
    }
    var $foundElement = $element.find(targetTag).first();
    if ($foundElement.length === 0) {
        return $element;
    }
    else if ($foundElement.length === 1) {
        return $foundElement; // Return the first found target element
    }
    else {
        console.error('There are multiple childrenb with the target ' + targetTag);
        return undefined;
    }
};
exports._FindElement = _FindElement;
/**
 * returns cash object if visible undefined if not
 * _IsVisible($('el'))?.append() is possible with this
 * @param domObject Cash
 * @returns Cash | undefined
 */
var _IsVisible = function (domObject) {
    var elem = domObject.get(0);
    return elem &&
        (elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
        ? domObject
        : undefined;
};
exports._IsVisible = _IsVisible;
var _IsInput = function (domObject) {
    if (domObject.is('input, select, textarea, button')) {
        return domObject;
    }
    return;
};
exports._IsInput = _IsInput;
