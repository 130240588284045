"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgileaApi = void 0;
var generator_const_1 = require("@src/const/dom-crud/generator.const");
var object_service_1 = require("../services/object-service");
var settings_service_1 = require("../services/settings.service");
var backend_services_1 = require("@src/services/backend-services");
var AgileaApi = /** @class */ (function () {
    function AgileaApi() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    AgileaApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, backend_services_1.betterFetch)(this.config.get.agileaUrl + '/studios?tenantKey=' + this.config.get.tenantKey, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var agileaStudios, mlStudios;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    agileaStudios = (_a.sent());
                                    mlStudios = agileaStudios.map(function (data) {
                                        return ({
                                            id: data.Studionummer,
                                            studioName: data.Studioname,
                                            studioPhone: data.phone,
                                            studioEmail: data.mail,
                                            trialSessionBookable: data.appointmentBooking,
                                            address: {
                                                street: data.street,
                                                city: data.city,
                                                zipCode: data.zip,
                                                countryCode: data.countryCode,
                                                countryCodeAlpha2: data.countryCode, // Assuming the same as `countryCode`
                                                longitude: +data.longitude,
                                                latitude: +data.latitude,
                                            },
                                            zoneId: 'Unknown Zone', // Placeholder if zone data is not available
                                            hasRateBundles: data.selfService,
                                            currencyCode: 'EUR', // Assuming EUR as default currency
                                        });
                                    });
                                    return [2 /*return*/, mlStudios];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    AgileaApi.prototype.createNewCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var agileaMember;
            var _this = this;
            return __generator(this, function (_a) {
                agileaMember = this.convertToAgileaCustomer(body);
                return [2 /*return*/, (0, backend_services_1.betterFetch)(this.config.get.agileaUrl + '/members?tenantKey=' + this.config.get.tenantKey, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(agileaMember),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        // Created response
                        if (this.config.get.environment === 'dev') {
                            console.log('created', created);
                        }
                        // Write response in localstorage for later usage
                        var dataArray = created;
                        for (var key in dataArray) {
                            if (dataArray[key]) {
                                localStorage.setItem(key, dataArray[key]);
                            }
                        }
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error === null || error === void 0 ? void 0 : error.message) {
                                        (0, generator_const_1._ShowErrorMessage)(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    AgileaApi.prototype.convertToAgileaCustomer = function (body) {
        return {
            studio_id: body.studioId,
            type: 2,
            firstName: body.customer.firstname,
            lastName: body.customer.lastname,
            gender: body.customer.gender === 'MALE' ? 'M' : body.customer.gender === 'FEMALE' ? 'W' : 'D',
            street: body.customer.street + ' ' + body.customer.houseNumber,
            zip: body.customer.zipCode,
            city: body.customer.city,
            phone1: body.customer.telephone_mobile,
            mobile: '', // Assuming no separate mobile field
            email: body.customer.email,
            mandatoryName: '', // Assuming empty if not provided
            birthday: body.customer.dateOfBirth,
            signingDate: body.contract.startDate,
            contractBegin: body.contract.startDate,
            accessBeginDate: body.contract.startDate,
            contractTemplateId: body.contract.rateBundleTermId,
            cPaymentTypes: 1, // Assuming a default payment type
            owner: "".concat(body.customer.firstname, " ").concat(body.customer.lastname),
            iban: body.customer.bankAccount.iban,
            bic: body.customer.bankAccount.bic,
        };
    };
    AgileaApi.prototype.getOfferings = function (studioId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, body, agileaContracts, MLContracts, error, error_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, (0, backend_services_1.betterFetch)(this.config.get.agileaUrl + '/templates?tenantKey=' + this.config.get.tenantKey + '&studioId=' + studioId, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })];
                    case 1:
                        res = _c.sent();
                        if (!res.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        body = _c.sent();
                        if (body) {
                            agileaContracts = body;
                            MLContracts = agileaContracts.map(function (contract) { return ({
                                id: contract.orderId,
                                description: contract.cDescription,
                                name: contract.cName,
                                subDescription: contract.wooDescription || null,
                                preuseType: 'defaultType', // Assuming a default value; replace as needed.
                                studioId: contract.studioId.toString(),
                                terms: [
                                    {
                                        id: contract.id,
                                        name: contract.cName,
                                        defaultContractStartDate: '2022-01-01', // Placeholder, adjust as needed.
                                        contractStartDateOfUse: '2022-01-01', // Placeholder, adjust as needed.
                                        termValue: contract.cLength,
                                        termValueWithoutBonusPeriod: contract.cLength, // Assuming no bonus period.
                                        termUnit: 'MONTH', // Assuming all terms are in months; adjust if needed.
                                        paymentFrequencyValue: 1, // Assuming monthly payments.
                                        paymentFrequencyUnit: 'MONTH',
                                        paymentFrequencyType: 'RECURRING',
                                        price: contract.cFee,
                                        currencyCode: 'EUR', // Update this once needed
                                        priceAdjustmentRules: [],
                                        convertedPriceAdjustmentRules: [],
                                        ageBasedAdjustmentDtos: [],
                                        flatFees: [],
                                        extensionFixedTerm: 0,
                                        extensionFixedTermUnit: 'MONTH',
                                        extensionType: 'none',
                                        cancellationPeriod: 1,
                                        cancellationPeriodUnit: 'MONTH',
                                        extensionCancellationPeriod: null,
                                        extensionCancellationPeriodUnit: null,
                                        rateBonusPeriods: [],
                                        rateStartPrice: contract.cFee,
                                        optionalModules: [],
                                    },
                                ],
                                modules: [],
                                maximumNumberOfSelectableModules: 0,
                                selectableModules: [],
                                rateCodeDto: [],
                                contractTextBlocks: [],
                                imageUrl: contract.productImageFilename ? "".concat(contract.productImageFilename) : null,
                                footnote: null,
                                initialPaymentRequired: true, // Assuming this needs to be true.
                                contractSignaturesRequired: false, // Assuming no signature required.
                                onlinePaymentType: 'ONLINE_CARD', // Assuming a payment type; adjust as needed.
                                allowedPaymentChoices: ['CARD', 'BANK_TRANSFER'], // Example choices.
                                limitedOfferingPeriod: null,
                            }); });
                            return [2 /*return*/, MLContracts];
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, res.json()];
                    case 4:
                        error = _c.sent();
                        throw error;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _c.sent();
                        if (((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.message) === null || _a === void 0 ? void 0 : _a.length) || ((_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.errorMessage) === null || _b === void 0 ? void 0 : _b.length)) {
                            return [2 /*return*/, undefined];
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AgileaApi.prototype.createLead = function (body) {
        return undefined;
    };
    //   // This can be used e.g. to validate a voucher
    AgileaApi.prototype.previewNewCustomer = function (body) {
        console.error('PREVIEW CUSTOMER NOT DEFINED FOR agilea API');
        return undefined;
    };
    AgileaApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.error('VOUCHERS NOT DEFINED FOR agilea API');
                return [2 /*return*/, undefined];
            });
        });
    };
    return AgileaApi;
}());
exports.AgileaApi = AgileaApi;
