"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports._SendDislike = exports._PerformRequest = exports._BaseUrl = void 0;
var backend_services_1 = require("@src/services/backend-services");
exports._BaseUrl = 'https://fitki-729907915433.europe-west3.run.app/';
var _PerformRequest = function (tenant, conversationId, data, firstContact, botName) { return __awaiter(void 0, void 0, void 0, function () {
    var urlTone, urlBotName, id, finalUrl;
    var _a;
    return __generator(this, function (_b) {
        urlTone = ((_a = data === null || data === void 0 ? void 0 : data.tone) === null || _a === void 0 ? void 0 : _a.length) ? '&tone=' + data.tone : '';
        urlBotName = (botName === null || botName === void 0 ? void 0 : botName.length) ? '&botName=' + botName : '';
        id = (conversationId === null || conversationId === void 0 ? void 0 : conversationId.length) ? '&conversationId=' + conversationId : '';
        finalUrl = exports._BaseUrl + "".concat(firstContact ? 'webchat-init' : 'chat-input', "?tenantKey=").concat(tenant).concat(id).concat(urlBotName).concat(urlTone);
        return [2 /*return*/, (0, backend_services_1.betterFetch)(finalUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(__assign({ tenantKey: tenant }, data)),
            }).then(function (response) {
                if (!response.ok) {
                    if (response.status === 500) {
                        throw 'retryButton';
                    }
                    if (response.status === 428) {
                        throw 'requestGPS';
                    }
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })];
    });
}); };
exports._PerformRequest = _PerformRequest;
var _SendDislike = function (tenant_1, chatId_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([tenant_1, chatId_1], args_1, true), void 0, function (tenant, chatId, path) {
        if (path === void 0) { path = ''; }
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, backend_services_1.betterFetch)(exports._BaseUrl + path + "not-helpful?conversationId=".concat(chatId, "&tenantKey=").concat(tenant), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(function (response) {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })];
        });
    });
};
exports._SendDislike = _SendDislike;
