"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiWrapper = void 0;
var object_service_1 = require("../services/object-service");
var settings_service_1 = require("../services/settings.service");
var agilea_api_1 = require("./agilea-api");
var athletics_api_1 = require("./athletics-api");
var magicline_api_1 = require("./magicline-api");
var none_api_1 = require("./none-api");
var yond_api_1 = require("./yond-api");
var themisoft_api_1 = require("./themisoft-api");
var ApiWrapper = /** @class */ (function () {
    function ApiWrapper() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    ApiWrapper.prototype.getApi = function () {
        var api;
        //By default we use magicline api
        api = object_service_1.ObjectService.getInstance(magicline_api_1.MagiclineApi);
        if (this.config.get.technologyType === 'athletics') {
            api = object_service_1.ObjectService.getInstance(athletics_api_1.AthleticsApi);
            console.log('Using API: ', this.config.get.technologyType);
        }
        if (this.config.get.technologyType === 'agilea') {
            api = object_service_1.ObjectService.getInstance(agilea_api_1.AgileaApi);
            console.log('Using API: ', this.config.get.technologyType);
        }
        if (this.config.get.technologyType === 'yond') {
            api = object_service_1.ObjectService.getInstance(yond_api_1.YondApi);
            console.log('Using API: ', this.config.get.technologyType);
        }
        if (this.config.get.technologyType === 'themisoft') {
            api = object_service_1.ObjectService.getInstance(themisoft_api_1.ThemisoftApi);
            console.log('Using API: ', this.config.get.technologyType);
        }
        if (this.config.get.technologyType === 'none') {
            api = object_service_1.ObjectService.getInstance(none_api_1.NoneApi);
            console.log('Using API: ', this.config.get.technologyType);
        }
        return api;
    };
    ApiWrapper.prototype.createNewCustomer = function (body) {
        console.log('new customer', body);
        return this.getApi().createNewCustomer(body);
    };
    ApiWrapper.prototype.createNewUnderageCustomer = function (body) {
        var _a;
        console.log('new customer', body);
        if ((_a = this.getApi()) === null || _a === void 0 ? void 0 : _a['createNewUnderageCustomer']) {
            return this.getApi()['createNewUnderageCustomer'](body);
        }
        else {
            console.error('THE TECHNOLOGY PARTNER DOESNT SUPPORT UNDERAGE ', this.config.get.technologyType);
        }
    };
    ApiWrapper.prototype.previewNewCustomer = function (body) {
        console.log('preview new customer', body);
        return this.getApi().previewNewCustomer(body);
    };
    ApiWrapper.prototype.getStudios = function () {
        return this.getApi().getStudios();
    };
    ApiWrapper.prototype.createLead = function (body) {
        return this.getApi().createLead(body);
    };
    ApiWrapper.prototype.validateVoucher = function (body) {
        console.log('new voucher', body);
        return this.getApi().validateVoucher(body);
    };
    ApiWrapper.prototype.getOfferings = function (studioId) {
        console.log('get offerings', studioId);
        return this.getApi().getOfferings(studioId);
    };
    return ApiWrapper;
}());
exports.ApiWrapper = ApiWrapper;
