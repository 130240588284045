"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VanillaBot = void 0;
var marked_1 = require("marked");
var ci_component_injector_service_1 = require("@src/services/ci-component-injector.service");
var send_requests_1 = require("./services/send-requests");
var PACKAGE = __importStar(require("../../../package.json"));
var VanillaBot = /** @class */ (function () {
    function VanillaBot() {
        this.conversation = [];
        this.assetURL = 'https://files.fittec.io/files/frontend/2.1.0/prod/fitKI/assets';
        this.conversationId = '';
        this.initialMessageTriggered = false;
        this.open = false;
        this.blockInputField = false;
        this.isFirstChat = true;
    }
    VanillaBot.prototype.legacySupport = function (settings) {
        var _a, _b, _c, _d, _e;
        if (((_a = settings === null || settings === void 0 ? void 0 : settings.tenant) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.settings.tenantKey) === null || _b === void 0 ? void 0 : _b.length)) {
            //if legacy implement
            this.settings.tenantKey = settings.tenant;
        }
        if (typeof this.settings.colorPallete.primaryBackground === 'string') {
            document.documentElement.style.setProperty('--light-action-primary-default', this.settings.colorPallete.primaryBackground);
            document.documentElement.style.setProperty('--dark-action-primary-default', this.settings.colorPallete.primaryBackground);
        }
        if (typeof this.settings.colorPallete.background === 'string') {
            document.documentElement.style.setProperty('var(--dark-background-default)', this.settings.colorPallete.background);
            document.documentElement.style.setProperty('var(--light-background-default)', this.settings.colorPallete.background);
        }
        if (typeof ((_c = this.settings.colorPallete) === null || _c === void 0 ? void 0 : _c.answerBubble) !== 'string') {
            document.documentElement.style.setProperty('--color-customer-color-light-200', (_e = (_d = this.settings.colorPallete) === null || _d === void 0 ? void 0 : _d.answerBubble) === null || _e === void 0 ? void 0 : _e.background);
        }
    };
    VanillaBot.prototype.loadModule = function (settings) {
        var _this = this;
        var _a, _b, _c, _d;
        this.settings = settings;
        this.legacySupport(settings);
        (0, ci_component_injector_service_1._InjectStencilWebComponents)();
        var chatWrapper = document.createElement('div');
        chatWrapper.style.cssText = 'position:absolute;z-index:10000';
        // Create and cast to our custom element interface
        this.ciChatWindow = document.createElement('ci-chat-window');
        this.ciLottiePlayer = document.createElement('ci-lottie-player');
        this.ciLottiePlayer.url = 'https://files.fittec.io/files/frontend/' + PACKAGE.version + '/dev/fitKI/assets/fitbot.json';
        this.ciLottiePlayer.size = '60px';
        var primaryBg = (_b = (_a = this.settings) === null || _a === void 0 ? void 0 : _a.colorPallete) === null || _b === void 0 ? void 0 : _b.primaryBackground;
        var color = typeof primaryBg === 'string'
            ? primaryBg.replace(/^#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])$/, '#$1$1$2$2$3$3')
            : '#333333';
        if (((_c = this.settings) === null || _c === void 0 ? void 0 : _c.theme) === 'dark') {
            this.ciLottiePlayer.replaceColor = { '#ffffff': 'transparent' };
            this.ciLottiePlayer.colorMapping = ['#ffffff', '#ffffff', '#ffffff', color, color, color, '#ffffff', '#ffffff'];
        }
        else {
            this.ciLottiePlayer.colorMapping = [color, color, color, '#ffffff', '#ffffff', '#ffffff', color, color];
        }
        // Set initial properties via attributes and direct assignment
        this.ciChatWindow.customIcon = true;
        this.ciChatWindow['dangerouslyRenderElements'] = true;
        this.ciChatWindow.setAttribute('toggle-button', 'true');
        this.ciChatWindow.setAttribute('toggle-button-title', 'fitbot - die beste ki um dich zu unterstützen');
        this.ciChatWindow.setAttribute('open', String(this.open));
        this.ciChatWindow.setAttribute('name', this.settings.name);
        this.ciChatWindow.setAttribute('sender-typing', 'true');
        this.ciChatWindow.setAttribute('theme', ((_d = this.settings) === null || _d === void 0 ? void 0 : _d.theme) || 'light');
        this.ciChatWindow.setAttribute('number-of-unread-messages', 'false');
        // if (this.settings?.showNumberOfUnreadChats) {
        //   this.ciChatWindow.setAttribute('number-of-unread-messages', String(this.settings.showNumberOfUnreadChats));
        // } else {
        //   this.ciChatWindow.setAttribute('number-of-unread-messages', '0');
        // }
        this.ciChatWindow.defaultUserAvatar = {
            shortcode: 'YOU',
        };
        if (this.settings.name || this.settings.image) {
            this.ciChatWindow.defaultSenderAvatar = {
                image: this.settings.image || "".concat(this.assetURL, "/bot-image.png"),
                username: this.settings.name,
            };
        }
        // Listen for changes from the chat window
        this.ciChatWindow.addEventListener('change', function (event) {
            var _a, _b, _c;
            var detail = event.detail;
            console.log(detail);
            if ((detail === null || detail === void 0 ? void 0 : detail.statusCode) === 12 && ((_b = (_a = detail === null || detail === void 0 ? void 0 : detail.sourceElement) === null || _a === void 0 ? void 0 : _a.getAttribute('text-for-ai')) === null || _b === void 0 ? void 0 : _b.length)) {
                var index = _this.conversation.findIndex(function (a) { var _a; return (_a = a === null || a === void 0 ? void 0 : a.components) === null || _a === void 0 ? void 0 : _a.length; });
                _this.conversation.splice(index, 1);
                var textForAI = (_c = detail === null || detail === void 0 ? void 0 : detail.sourceElement) === null || _c === void 0 ? void 0 : _c.getAttribute('text-for-ai');
                _this.sendMessageTofitbot({
                    tenantKey: _this.settings.tenantKey,
                    operation: 'simple',
                    textInput: textForAI,
                });
            }
            if ((detail === null || detail === void 0 ? void 0 : detail.statusCode) !== 11)
                return;
            var data = detail.data;
            if (data.reactionTriggered === 'fittec-thumb-down') {
                (0, send_requests_1._SendDislike)(_this.settings.tenantKey, _this.conversationId);
            }
            if ((data === null || data === void 0 ? void 0 : data.open) !== undefined) {
                _this.open = data.open;
                _this.updateChatWindow();
                if (!_this.initialMessageTriggered && _this.open) {
                    _this.triggerInitialMessage();
                }
            }
            if (data === null || data === void 0 ? void 0 : data.message) {
                var messageObj = data.message;
                _this.sendMessageTofitbot({
                    tenantKey: _this.settings.tenantKey,
                    operation: 'simple',
                    textInput: messageObj.message,
                });
            }
        });
        // Optionally open after a delay
        if (this.settings.openAfterSeconds && this.settings.openAfterSeconds > 0) {
            setTimeout(function () {
                _this.open = true;
                _this.updateChatWindow();
                if (!_this.initialMessageTriggered && _this.open) {
                    _this.triggerInitialMessage();
                }
            }, this.settings.openAfterSeconds * 1000);
        }
        chatWrapper.appendChild(this.ciChatWindow);
        document.body.appendChild(chatWrapper);
        this.ciChatWindow.appendChild(this.ciLottiePlayer);
    };
    VanillaBot.prototype.updateChatWindow = function () {
        this.ciChatWindow.setAttribute('open', String(this.open));
        this.ciChatWindow.messages = this.conversation;
    };
    VanillaBot.prototype.triggerInitialMessage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var components;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.open && !this.initialMessageTriggered)) return [3 /*break*/, 2];
                        this.initialMessageTriggered = true;
                        return [4 /*yield*/, this.sendMessageTofitbot({
                                tenantKey: this.settings.tenantKey,
                                operation: 'simple',
                                textInput: '',
                            })];
                    case 1:
                        _c.sent();
                        if ((_b = (_a = this.settings) === null || _a === void 0 ? void 0 : _a.initialButtons) === null || _b === void 0 ? void 0 : _b.length) {
                            components = this.settings.initialButtons
                                .map(function (a) {
                                return [
                                    {
                                        component: 'ci-button',
                                        attributes: {
                                            class: 'm-4',
                                            option: 'primary',
                                            'text-for-ai': a.sendText,
                                        },
                                        innerHTML: a.label,
                                    },
                                ];
                            })
                                .flat();
                            this.conversation.push({
                                date: new Date(),
                                message: 'Beliebte anfragen',
                                tranceiver: 'user',
                                components: components,
                            });
                        }
                        this.updateChatWindow();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VanillaBot.prototype.sendMessageTofitbot = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if ((_a = data === null || data === void 0 ? void 0 : data.textInput) === null || _a === void 0 ? void 0 : _a.length) {
                            this.conversation.push({
                                date: new Date(),
                                message: data.textInput,
                                tranceiver: 'user',
                            });
                        }
                        this.updateChatWindow();
                        return [4 /*yield*/, this.requestAIResponse(data)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VanillaBot.prototype.requestAIResponse = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var response, parsedAnswer, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ciChatWindow.setAttribute('sender-typing', 'true');
                        this.ciChatWindow.senderTyping = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 7]);
                        return [4 /*yield*/, (0, send_requests_1._PerformRequest)(this.settings.tenantKey, this.conversationId, data, this.isFirstChat)];
                    case 2:
                        response = _a.sent();
                        this.ciChatWindow.setAttribute('sender-typing', 'false');
                        this.ciChatWindow.senderTyping = false;
                        if (response === null || response === void 0 ? void 0 : response.conversationId) {
                            this.conversationId = response.conversationId;
                        }
                        return [4 /*yield*/, (0, marked_1.marked)(response.answer)];
                    case 3:
                        parsedAnswer = _a.sent();
                        this.conversation.push({
                            date: new Date(),
                            message: parsedAnswer,
                            tranceiver: 'sender',
                        });
                        return [3 /*break*/, 7];
                    case 4:
                        error_1 = _a.sent();
                        console.error('KI ERROR', error_1);
                        if (error_1 === 'retryButton') {
                            this.conversation.push({
                                message: 'Oh nein es gab einen Fehler. Beim drücken auf den knopf versuchen wir es nochmal.',
                                date: new Date(),
                                tranceiver: 'sender',
                                components: [
                                    {
                                        component: 'ci-button',
                                        attributes: {},
                                        innerHTML: 'Neuer versuch',
                                    },
                                ],
                            });
                        }
                        if (!(error_1 === 'requestGPS')) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.geoLocationRequested(__assign({}, data))];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7:
                        this.updateChatWindow();
                        this.ciChatWindow.setAttribute('sender-typing', 'false');
                        this.ciChatWindow.senderTyping = false;
                        this.isFirstChat = false;
                        this.blockInputField = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    VanillaBot.prototype.geoLocationRequested = function (data) {
        var _this = this;
        console.error('REQUEST GPS');
        if (!navigator.geolocation) {
            console.log('Geolocation is not supported by this browser.');
            return this.requestAIResponse(__assign(__assign({}, data), { location: 'permission denied' }));
        }
        var requestPosition = function () {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.requestAIResponse(__assign(__assign({}, data), { location: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    } }));
            }, function () {
                _this.requestAIResponse(__assign(__assign({}, data), { location: 'permission denied' }));
            });
        };
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === 'granted') {
                    requestPosition();
                }
                else {
                    _this.conversation.push({
                        date: new Date(),
                        tranceiver: 'sender',
                        message: 'Bitte teile den Standort hier im Browser um die Frage bestmöglich zu beantworten',
                        components: [
                            {
                                component: 'ci-icon',
                                attributes: {
                                    icon: 'location',
                                    class: 'm-8',
                                    size: '20px',
                                    style: { display: 'block' },
                                },
                                innerHTML: '',
                            },
                        ],
                    });
                    requestPosition();
                }
            });
        }
        else {
            // Fallback if Permissions API is unavailable
            this.conversation.push({
                date: new Date(),
                tranceiver: 'sender',
                message: 'Bitte teile den Standort hier im Browser um die Frage bestmöglich zu beantworten',
                components: [
                    {
                        component: 'ci-icon',
                        attributes: {
                            icon: 'location',
                            class: 'm-8',
                            size: '20px',
                            style: { display: 'block' },
                        },
                        innerHTML: '',
                    },
                ],
            });
            requestPosition();
        }
    };
    return VanillaBot;
}());
exports.VanillaBot = VanillaBot;
