"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._InjectCss = exports.injectedClasses = void 0;
exports.injectedClasses = {};
var _InjectCss = function (className, style) {
    if (!exports.injectedClasses[className]) {
        var styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        styleSheet.innerText = style;
        document.head.appendChild(styleSheet);
        exports.injectedClasses[className] = true;
    }
};
exports._InjectCss = _InjectCss;
